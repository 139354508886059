@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 20px;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #94a3b8;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #475569;
    border-radius: 100vh;
    border: 3px solid #475569;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #1e293b;
  }
}
